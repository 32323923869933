<template>
  <div class="index-task">
    <div v-if="$can(contentKey, account)" class="vip">
      <el-row :span="24" :gutter="10">
        <el-col :span="12">
          <el-card style="height: 90vh">
            <template #header>
              <div class="clearfix">
                <span>未完成</span>
              </div>
            </template>
            <el-scrollbar style="max-height: 80vh">
              <task-list
                :data="undoneTask"
                :no-data="noDoneData"
                @get-schedules="getSchedules"
              ></task-list>
            </el-scrollbar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="height: 90vh">
            <template #header>
              <div class="clearfix">
                <span>已完成</span>
              </div>
            </template>
            <el-scrollbar style="max-height: 80vh">
              <task-list :data="doneTask" :no-data="noUndoneData"></task-list>
            </el-scrollbar>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div v-else class="customer">
      <el-row>
        <div class="image-wrapper"></div>
      </el-row>
    </div>
  </div>
</template>

<script>
import Api from "api";
import axios from "@/common/js/ajax";
import { normalMixin } from "@/common/js/mixin";
import { SUCC_MSG } from "@/common/js/config";
import notify from "@/common/js/notify";
import time from "@/common/js/time";
import { ability } from "@/ability/template";
import { mapGetters } from "vuex";
import { isDef } from "@/common/js/utils";
import { formatDateTime } from "@/common/js/filters";
import _ from "lodash";

import TaskList from "./task-list";

const ACCOMPLIST_SUCC_INFO = "任务" + SUCC_MSG;

export default {
  filters: {
    formatDateTime(value) {
      if (!value) return "无";
      return formatDateTime(value);
    },
  },
  components: { TaskList },
  mixins: [normalMixin],
  data() {
    return {
      currentDate: new Date(),
      task: [],
      doneTask: [],
      undoneTask: [],
      noData: false,
      noDoneData: false,
      noUndoneData: false,
    };
  },
  computed: {
    ...mapGetters(["account"]),
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.contentKey = ability.index.indexTask.content;
      this.getSchedules();
    },
    getSchedules() {
      const url = Api.getSchedules;
      axios({
        method: "get",
        url: url,
      })
        .then((res) => {
          res.sort((a, b) => {
            return new Date(b.startDate) - new Date(a.startDate);
          });
          res = res.map((o) => {
            o.loading = false;
            o.createdDate = time.getFormatDateTime(o.createdDate);
            return o;
          });
          const data = _.groupBy(res, (a) => a.done);

          // const result = data[false].concat(data[true]);
          // if (!isDef(result)) {
          //   this.noData = true;
          // }
          this.doneTask = data[true];
          if (!isDef(this.doneTask)) {
            this.noDoneData = true;
          }
          this.undoneTask = data[false];
          if (!isDef(this.undoneTask)) {
            this.noUndoneData = true;
          }
        })
        .catch(() => {});
    },
    accomplish(item) {
      const { id } = item;
      const url = Api.putSchedulesDone.replace("{id}", id);
      item.loading = true;

      axios({
        method: "put",
        url: url,
      })
        .then(() => {
          item.loading = false;
          this.getSchedules();
          notify.succ({ message: ACCOMPLIST_SUCC_INFO });
        })
        .catch(() => {
          item.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.index-task {
  width 100%;
  .image-wrapper {
    background-image: url(../../../../assets/images/banner.png);
    width: 100%;
    height: 50rem;
    background-repeat: no-repeat;
    background-position: 50%, 50%;
  }
}

.time {
  font-size: 13px;
  color: #999;
}
.task_title {
  text-align: left;
  color: $succ;
}
.task-description {
  margin 0.5rem
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  font-size: 0.9rem
}
.underline {
  text-decoration: line-through;
}

.button {
  /* padding: 0; */
  float: right;
  border: 1px solid $prim;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.doneColor {
  color: #c0c4cc;
}

img {
  max-width: 100%;
  max-height: 100%;
}
.noData
    text-align: center;
    font-size: 1.2rem;
    color: #ccc;
    margin-top 3rem
</style>
